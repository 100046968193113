<template>
  <div id="app">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true" />
    <Navbar />
    <router-view />
    <InstagramFeed />
    <Footer />
    <MobileMenu />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import InstagramFeed from "@/components/InstagramFeed.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    InstagramFeed,
    Footer,
    MobileMenu,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    console.log("APP MOUNTED!");
    console.log("Begin Token Check!");
    if (!localStorage.lilToken) {
      this.getToken();
    } else {
      console.log("Token Present!");
      this.checkToken();
    }
    window.addEventListener("load", () => {
      this.isLoading = false;
    });
    // this.isLoading = true;
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 3000);
  },
  methods: {
    getToken() {
      console.log("Getting Token..");
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/token.php`, {
          action: "getToken",
        })
        .then((response) => {
          //console.log("RESPONCE:" + JSON.stringify(response));
          //console.log(response.data.message);
          if (response.data.success) {
            localStorage.lilToken = response.data.token;
          }
        })
        .catch((error) => {
          //this.isLoading = false;
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    checkToken() {
      if (localStorage.lilToken) {
        console.log("Checking local Token..");
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validate.php`, {
            token: localStorage.lilToken,
          })
          .then((response) => {
            //console.log("RESPONCE:" + JSON.stringify(response));
            console.log(response.data.message);
            if (!response.data.success) {
              localStorage.removeItem("lilToken");
              this.getToken();
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Token Not found!");
        this.getToken();
      }
    },
  },
};
</script>

<style lang="scss">
html {
  -webkit-tap-highlight-color: transparent;
}
*,
*:before,
*:after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
@import "~@/static/intensifies.css";
// html {
//   scroll-behavior: smooth;
// }
#app {
  background: #000;
}
$loading-background: #000;
//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Work+Sans:ital,wght@0,700;0,900;1,700;1,900&display=swap");

$family-primary: "Open Sans", sans-serif; //'Roboto', sans-serif;
$family-secondary: "Work Sans", sans-serif;

$weight-normal: 500;

$body-color: #2e2e2e;

//font-family: 'Open Sans', sans-serif;
//font-family: 'Work Sans', sans-serif;

// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #d59a2b; //#4ab600;
$secondary: #1b5531;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
$secondary-invert: findColorInvert($secondary);
//theme mod
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

//$label-weight: 100;
//$label-color: $primary;

//$navbar-item-color: $tcd-gray!important;
//$navbar-dropdown-arrow: $tcd-gray!important;

//$footer-background-color: $tcd-gray;
//$footer-color: $tcd-light2;

$footer-padding: 2rem 1.5rem 2rem;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);

// Links
$link: $secondary;
$link-invert: $secondary-invert;
$link-focus-border: $primary;

// $input-icon-color: $tcd-light;
// $input-icon-active-color: $tcd-gray;

$card-header-background-color: $primary;

.section-title {
  font-family: "Work Sans", sans-serif;
  font-weight: 900;
  font-size: 2.3em;
  font-style: italic;
  line-height: 1;
  text-transform: uppercase;
}

.b-checkbox.checkbox .control-label:hover {
  color: #d59a2b !important;
}

.osect {
  overflow: hidden;
}

// .content li + li {
//      margin-top: 0em!important;
// }

// .content ul {
//     margin-left: 0em!important;
//     margin-top: 0em!important;
// }

// .control.has-icons-right .input,
// .control.has-icons-right .select select {
//   padding-right: 0.6em !important;
// }

// .message textarea {
//   border: 0;
//   box-shadow: none !important;
//   border-radius: 0 !important;
//   border-bottom: 1px solid #000000 !important;
//   resize: none;
//   padding-left: 0 !important;
// }

// input {
//   border: 0 !important;
//   outline: 0;
//   border-radius: 0 !important;
//   border-bottom: 1px solid #000000 !important;
//   box-shadow: none !important;
//   padding-left: 0 !important;
//   padding-bottom: 0px !important;
// }

// .active-label .label {
//   font-size: calc(1rem * 0.75) !important;
//   margin-top: -5px;
// }

// .label {
//   transition: all 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
//   margin-top: 15px;
//   position: absolute;
//   z-index: 5;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

// .label {
//   // font-weight: 100!important;
//   // color: #000000;
//   white-space: nowrap;
//   pointer-events: none;
// }
.screen {
  background-color: #000;
  margin-top: 7px;
  width: 70vw;
  height: 60vh;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: 4px solid #16253642;
  transform: perspective(80px) rotateX(2deg);
  //box-shadow: inset 5px 5px 15px 7px rgba(255,255,255,0.72), inset 0px -28px 22px -17px rgba(255,82,178,0.49), inset -32px -2px 22px -17px rgba(2,46,92,0.51);
  box-shadow: inset 0px -28px 22px -17px rgba(255, 82, 178, 0.49),
    inset -32px -2px 22px -17px rgba(2, 46, 92, 0.51),
    inset 24px -2px 22px -17px rgba(2, 46, 92, 0.51),
    inset 0px 32px 23px -16px rgba(255, 255, 255, 0.55);
}

@media only screen and (max-width: 600px) {
  .screen {
    width: 85vw;
    height: 40vh;
  }
}

@media only screen and (min-width: 600px) {
  .screen {
    width: 70vw;
    height: 40vh;
  }
}

@media only screen and (min-width: 768px) {
  .screen {
    width: 70vw;
    height: 40vh;
  }
}

@media only screen and (min-width: 992px) {
  .screen {
    width: 70vw;
    height: 55vh;
  }
}

@media only screen and (min-width: 1200px) {
  .screen {
    width: 70vw;
    height: 60vh;
  }
}

@media only screen and (max-height: 600px) {
  .screen {
    width: 78vw;
    height: 76vh;
  }
}

.line-separator {
  background: url("~@/assets/lines.jpg");
  height: 96px;
  width: 100%;
}

.section-ct {
  max-width: 800px;
  padding: 40px 40px 40px 40px;
  text-align: center;
  // margin: 40px 40px 40px 40px;
  border: 1px solid #325268;
  outline: 2px solid #122837;
  border-radius: 30px;
  transform-style: preserve-3d;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .section-ct {
      border: 2px solid #122837;
      outline: unset;
    }
    .screen {
      outline: unset;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.clickable {
  cursor: pointer;
}

.no-click {
  pointer-events: none;
  touch-action: none;
}

.help {
  text-align: right !important;
}
.message {
  text-align: right !important;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

@import "./static/pixel-borders/pixel-borders.scss";
</style>
