import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modals: {
      menu: false,
    },
  },
  getters: {},
  mutations: {
    show(state, payload) {
      state.modals[payload] = true;
    },
    hide(state, payload) {
      state.modals[payload] = false;
    },
    toggle(state, payload) {
      state.modals[payload] = !state.modals[payload];
    },
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
  },
  actions: {},
  modules: {},
});
