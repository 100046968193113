<template>
  <div class="section story-section osect">
    <div class="lil-sticker">
      <img src="~@/assets/sticker.png" alt="" />
    </div>
    <div class="container is-max-desktop story-container story-layer">
      <div class="copy-column">
        <div class="section-title story-title" v-html="sectionHeading"></div>
        <div class="story-text" v-html="sectionContent"></div>
        <div class="story-bt">
          <div
            class="pixel-borders pixel-bt-pink mt-5"
            @click="gotoPage('about')"
          >
            learn more
          </div>
        </div>
      </div>
    </div>
    <div class="arcade-container">
      <div ref="loani" class="loani"></div>
      <div class="arcade"><img src="~@/assets/arcade.svg" alt="" /></div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SectionStoryHome",
  props: ['sectionHeading', 'sectionContent'],
  mounted() {
    let screeWidth = window.innerWidth;
    let maxOpacity = 1;
    let stickerScale = 1;
    let stickerPosY = 0;
    if (screeWidth > 600) {
      if (screeWidth < 768) {
        maxOpacity = 0.15;
      }
      if (screeWidth < 1440) {
        stickerScale = 0.7;
        stickerPosY = -100;
      }
      gsap
        .fromTo(
          ".lil-sticker",
          { opacity: 0, x: -200, y: -200, scale: 2 },
          {
            duration: 1.5,
            opacity: 1,
            x: 0,
            y: stickerPosY,
            scale: stickerScale,
            scrollTrigger: {
              trigger: ".story-section",
              start: "top 75%",
              end: "bottom top",
            },
            ease: "expo.out",
          }
        )
        .delay(0.1);

      gsap
        .fromTo(
          ".story-title, .story-text, .story-bt",
          { opacity: 0, y: 200 },
          {
            duration: 1.5,
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: ".story-section",
              start: "top 75%",
              end: "bottom top",
            },
            ease: "expo.out",
          }
        )
        .delay(0.1);

      gsap
        .fromTo(
          ".arcade-container",
          { opacity: 0, x: 200 },
          {
            duration: 1,
            x: 0,
            opacity: maxOpacity,
            scrollTrigger: {
              trigger: ".story-section",
              // start:"top 75%",
              // end:"bottom 5%",
              start: "top 75%",
              end: "bottom top",
              ease: "sine.out",
              //markers: true,
              //toggleActions: "restart reset play",
            },
          }
        )
        .delay(0.1);
    } //end momile if
    lottie.loadAnimation({
      container: this.$refs.loani,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.$env.siteURL + "anim/crown_burger.json",
    });
  },
  methods: {
    gotoPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style scoped lang="scss">
.story-section {
  background: url("~@/assets/aboutBlueBG.jpg"); //homeBanner.jpg
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  // width: 100%;
  // // height: ;
  // display: flex;
  // align-content: center;
  // justify-content: center;
  position: relative;
}

.story-container {
  padding: 200px 40px 100px 40px;
}

.story-title {
  color: #294653;
  margin-bottom: 20px;
}

.story-text {
  // color: #ffffff;
  font-size: 1.2em;
}

.arcade-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 500px;
  opacity: 0;
}

.arcade {
  width: 500px;
  margin-bottom: -6px;
}

.loani {
  position: absolute;
  width: 180px;
  margin-left: 130px;
  margin-top: 130px;
}

.story-layer {
  z-index: 5;
}

.lil-sticker {
  position: absolute;
}

@media only screen and (max-width: 768) {
  .arcade-container {
    opacity: 0.15;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .lil-sticker {
    width: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .lil-sticker {
    width: 314px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .lil-sticker {
    width: 314px;
  }
  .copy-column {
    width: 50%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .lil-sticker {
    width: 314px;
  }
  .copy-column {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  // .rollers-container {
  //   opacity: 0.3;
  // }
  .copy-column {
    width: 80%;
  }
}

@media only screen and (min-width: 1440px) {
  // .arcade-container {
  //   opacity: 1;
  // }
  // .rollers-container {
  //   opacity: 0.3;
  // }
  .copy-column {
    width: 100%;
  }
}

@media only screen and (min-width: 1825px) {
  .lil-sticker {
    margin-left: 200px;
  }
}
</style>
