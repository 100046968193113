<template>
  <section class="contact-form">
    <b-field :type="form.name.status" :message="form.name.reply">
      <b-input placeholder="First Name" v-model="form.name.data"></b-input>
    </b-field>

    <b-field :type="form.surname.status" :message="form.surname.reply">
      <b-input placeholder="Last Name" v-model="form.surname.data"></b-input>
    </b-field>

    <b-field :type="form.email.status" :message="form.email.reply">
      <b-input placeholder="Email" type="email" v-model="form.email.data">
      </b-input>
    </b-field>

    <b-field :type="form.message.status" :message="form.message.reply">
      <b-input
        placeholder="Message"
        type="textarea"
        v-model="form.message.data"
      ></b-input>
    </b-field>
    <b-message
      auto-close
      :duration="10000"
      :active.sync="form.result"
      :type="form.status"
    >
      {{ form.reply }}
    </b-message>
    <div class="has-text-centered apply-bt">
      <div class="pixel-borders pixel-bt-gold" @click="submitForm()">
        Send Message
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      isLoading: false,
      form: {
        name: {
          data: null,
          status: null,
          reply: null,
        },
        surname: {
          data: null,
          status: null,
          reply: null,
        },
        email: {
          data: null,
          status: null,
          reply: null,
        },
        message: {
          data: null,
          status: null,
          reply: null,
        },
        status: null,
        reply: null,
        result: false,
        done: false,
      },
    };
  },
  methods: {
    submitForm() {
      console.log("name", this.form.name.data);
      console.log("surname", this.form.surname.data);
      console.log("email", this.form.email.data);
      console.log("message", this.form.message.data);
      if (
        this.formCheck("name") &
        this.formCheck("surname") &
        this.formCheck("message") &
        this.emailCheck()
      ) {
        console.log("good!");
        // this.form.status = "is-warning";
        // this.form.result = true;
        // this.form.reply = "Believe you me, I've tried!";
        this.sendMessage();
        //this.sendBooking();
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.reply = "Please fill out the form!";
        console.log("not good!");
        console.log("formCheck", this.formCheck("name"));
        console.log("formCheck", this.formCheck("surname"));
        console.log("emailCheck", this.emailCheck());
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].reply = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].reply = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    emailCheck() {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form.email.data === "" || !re.test(this.form.email.data)) {
        this.form.email.status = "is-danger";
        this.form.email.reply = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        this.form.email.reply = null;
        return true;
      }
    },
    sendMessage() {
      this.isLoading = true;
      console.log("Sending Message...");
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/notify.php`, {
          token: localStorage.lilToken,
          name: this.form.name.data,
          surname: this.form.surname.data,
          email: this.form.email.data,
          //phone: this.form.phone.data,
          message: this.form.message.data,
        })
        .then((response) => {
          this.isLoading = false;
          //console.log("RESPONCE:" + JSON.stringify(response));
          //console.log(response);
          //console.log(response.data.message);
          this.form.result = true;
          this.form.reply = response.data.message;
          if (response.data.success) {
            console.log(response.data);
            this.form.status = "is-success";
            this.form.done = true;
          } else {
            this.form.status = "is-danger";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.reply = "Sorry, something went wrong, please try again.";
        });
      // .finally(() => {
      //   this.form.done = true;
      // });
      //end post-------------------------------------------------
    },
  },
};
</script>
<style scoped></style>
