<template>
  <div class="home">
    <SectionGridHome :sectionHeading="gridSection.title" :sectionContent="gridSection.content" />
    <SectionIceburgerHome :sectionHeading="menuSection.title" :sectionContent="menuSection.content" />
    <SectionStoryHome :sectionHeading="storySection.title" :sectionContent="storySection.content" />
    <div class="line-separator"></div>
    <CombatSection />
    <SectionMerchHome :sectionHeading="shopSection.title" :sectionContent="shopSection.content" />
  </div>
</template>

<script>
//import SectionOne from "@/components/SectionOne.vue";
import SectionGridHome from "@/components/home/SectionGridHome.vue";
import SectionIceburgerHome from "@/components/home/SectionIceburgerHome.vue";
import SectionStoryHome from "@/components/home/SectionStoryHome.vue";
import SectionMerchHome from "@/components/home/SectionMerchHome.vue";
import CombatSection from "@/components/CombatSection.vue";
export default {
  name: "HomeView",
  components: {
    //SectionOne,
    SectionGridHome,
    SectionIceburgerHome,
    SectionStoryHome,
    SectionMerchHome,
    CombatSection,
  },
  data() {
    return {
      gridSection: {
        title: "",
        content: "",
      },
      menuSection: {
        title: "",
        content: "",
      }, 
      storySection: {
        title: "",
        content: "",
      }, 
      shopSection: {
        title: "",
        content: "",
      }, 
    }
  },
  methods: {
    getPageData: function() {

      this.axios
        .get(this.$apiUrl + 'wp-json/lilempire/v1/get-page/8')
        .then(response => {
          //console.log(response.data)
          
          this.gridSection = {
            title: response.data.acf.grid_section.title,
            content: response.data.acf.grid_section.content
          }

          this.menuSection = {
            title: response.data.acf.menu_section.title,
            content: response.data.acf.menu_section.content
          }

          this.storySection = {
            title: response.data.acf.story_section.title,
            content: response.data.acf.story_section.content
          }

          this.shopSection = {
            title: response.data.acf.shop_section.title,
            content: response.data.acf.shop_section.content
          }
        }
      )
    },
  },
  mounted: function() {
    this.getPageData()
  },
  metaInfo() {
    return {
      title: this.$env.siteTitle,
      htmlAttrs: {
        lang: "en",
      },
      meta: [
        {
          name: "description",
          content: this.$env.siteDescription,
        },
        {
          property: "og:title",
          content: this.$env.siteTitle,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$env.siteDescription,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: this.$env.shareImg,
          vmid: "og:image",
        },
        {
          property: "og:url",
          content: this.$env.siteUrl,
          vmid: "og:url",
        },
      ],
    };
  }, //end meta
};
</script>
