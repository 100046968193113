<template>
  <section class="menu-modal">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-modal :active.sync="isActive" trap-focus>
      <div class="blank-modal">
        <div class="menu-logo"><img src="~@/assets/lilLogo.svg" alt="" /></div>
        <ul>
          <li>
            <router-link
              to="/"
              @click.native="navigate()"
              :class="$route.name == 'home' ? 'mm-active' : ''"
              >home</router-link
            >
          </li>
          <li>
            <router-link
              to="/menu"
              @click.native="navigate()"
              :class="$route.name == 'menu' ? 'mm-active' : ''"
              >menu</router-link
            >
          </li>
          <li>
            <router-link
              to="/about"
              @click.native="navigate()"
              :class="$route.name == 'about' ? 'mm-active' : ''"
              >about</router-link
            >
          </li>
          <li><a href="#">swag</a></li>
          <li>
            <router-link
              to="/contact"
              @click.native="navigate()"
              :class="$route.name == 'contact' ? 'mm-active' : ''"
              >contact</router-link
            >
          </li>
          <li>
            <router-link
              to="/join"
              @click.native="navigate()"
              :class="$route.name == 'join' ? 'mm-active' : ''"
              >join us</router-link
            >
          </li>
          <!-- <li>
            <router-link
              to="/community"
              @click.native="navigate()"
              :class="$route.name == 'community' ? 'mm-active' : ''"
              >community</router-link
            >
          </li> -->
        </ul>
        <div
          class="pixel-borders pixel-bt my-6"
          @click="gotoURL('https://www.lilempireburger.ca/')"
        >
          place an order
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
//import { mapState } from "vuex";
export default {
  name: "MobileMenu",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    gotoURL(url) {
      window.open(url, "_blank").focus();
    },
    navigate() {
      this.closeModal();
    },
    closeModal() {
      this.$store.commit("hide", "menu");
    },
  },
  computed: {
    //...mapState(["modalData"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.menu;
      },
      // setter
      set: function () {
        this.$store.commit("hide", "menu");
        return false;
      },
    },
  },
};
</script>
<style scoped>
.blank-modal {
  padding-right: 20px;
  max-height: 90vh;
  overflow: hidden;
  text-align: center;
}
.menu-logo {
  width: 300px;
  margin: auto;
  margin-bottom: 30px;
}

a {
  font-family: "Work Sans", sans-serif;
  /* line-height: 1; */
  font-style: italic;
  text-shadow: 4px 4px #0c2835;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2em;
  color: #fff;
  position: relative;
}

a:hover {
  color: #d59a2b;
}

.mm-active:before {
  content: url("~@/assets/crown.svg");
  position: absolute;
  top: -7px;
  left: -35px;
  /* left: 50%;
  transform: translate(-50%); */
  width: 30px;
  height: 30px;
}

.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar-track {
  background-color: #363636 !important;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #363636 !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #000 !important;
}
</style>
