<template>
  <div class="noise-wrap">
    <div class="noise-container">
      <div @click="countClick()" class="noise"></div>
      <div v-if="game" class="game"><pongGame /></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import pongGame from "@/components/screens/pongGame.vue";
export default {
  name: "HomeScreen",
  components: {
    pongGame,
  },
  data() {
    return {
      game: false,
      clicks: 0,
    };
  },
  mounted() {
    var noiseTL = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });
    noiseTL.to(".noise", {
      duration: 0.1,
      backgroundPositionY: -4 + "px",
      //ease: "steps(1)",
    });
  },
  methods: {
    countClick() {
      if (this.clicks > 3) {
        this.game = !this.game;
      } else {
        this.clicks++;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.noise-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  transform: translateZ(0);
}
.noise-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.noise {
  position: absolute;
  background: url("~@/assets/noise.png");
  opacity: 0.1;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.game {
  // width: 100vw;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

// .game {
//   transform: scale(0.5);
// }
</style>
