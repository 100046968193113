<template>
  <div class="lil-footer-bg">
    <div class="lil-footer">
      <div class="lil-footer-logo">
        <img src="~@/assets/lilLogo.svg" alt="" />
      </div>
      <div class="lil-footer-menu">
        <div class="lil-footer-menu-container">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/menu">Menu</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li>
              <a href="https://www.lilempireburger.ca/#5" target="_blank"
                >swag</a
              >
            </li>
            <li><router-link to="/contact">Contact</router-link></li>
            <li><router-link to="/join">Join us</router-link></li>
            <li>
              <router-link to="/community">Community Champions</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-text">
          <span class="footer-text-primary">Annex:</span> 4321 1st SE |
          <span class="footer-text-primary">Bridgeland:</span> 1105 1Ave NE |
          <span class="footer-text-primary">Capitol Hill:</span> Coming Soon
        </div>
      </div>
      <div class="lil-footer-sub">
        <b-field :type="form.email.status" :message="form.email.reply">
          <b-input
            placeholder="Your Email"
            type="email"
            v-model="form.email.data"
          ></b-input>
          <p class="control">
            <b-button
              @click="submitForm()"
              type="is-primary"
              label="Subscribe"
            />
          </p>
        </b-field>
        <b-field>
          <b-checkbox v-model="form.agree.data" size="is-small"
            >I agree to receive emails and updates</b-checkbox
          >
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      form: {
        email: {
          data: null,
          status: null,
          reply: null,
        },
        agree: {
          data: null,
          status: null,
          reply: null,
        },
        status: null,
        reply: null,
        result: false,
        done: false,
      },
    };
  },
  mounted() {
    //this.getFeed();
  },
  methods: {
    submitForm() {
      console.log("email", this.form.email.data);
      if (this.emailCheck() && this.agreeCheck()) {
        console.log("good!");
        // this.form.status = "is-warning";
        // this.form.result = true;
        // this.form.reply = "Believe you me, I've tried!";
        this.subscribeUser();
        //this.sendBooking();
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.reply = "Please fill out the form!";
        console.log("not good!");
        console.log("emailCheck", this.emailCheck());
      }
    },
    subscribeUser() {
      this.isLoading = true;
      console.log("Subscribing User...");
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/subscribe.php`, {
          token: localStorage.lilToken,
          email: this.form.email.data,
        })
        .then((response) => {
          this.isLoading = false;
          //console.log("RESPONCE:" + JSON.stringify(response));
          //console.log(response);
          console.log(response.data);
          if (response.data.success) {
            console.log(response.data);
            this.form.status = "is-success";
            this.form.done = true;
            this.showToast("is-success", response.data.message);
          } else {
            this.showToast("is-danger", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.showToast(
            "is-danger",
            "Sorry, something went wrong, please try again."
          );
        });
      // .finally(() => {
      //   this.form.done = true;
      // });
      //end post-------------------------------------------------
    },
    emailCheck() {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form.email.data === "" || !re.test(this.form.email.data)) {
        this.form.email.status = "is-danger";
        this.form.email.reply = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        this.form.email.reply = null;
        return true;
      }
    },
    agreeCheck() {
      if (this.form.agree.data === true) {
        return true;
      } else {
        this.showToast(
          "is-danger",
          "Please agree to receive emails and updates"
        );
        return false;
      }
    },
    showToast(type, message) {
      this.$buefy.toast.open({
        message: message,
        type: type,
        position: "is-bottom",
      });
    },
    gotoURL(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>
<style scoped>
.lil-footer {
  display: flex;
  flex-direction: row;
  min-height: 174px;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
}

.lil-footer-bg {
  background: url("~@/assets/footer.jpg");
  background-size: cover;
}

.lil-footer-logo {
  width: 200px;
  margin-right: 50px;
  margin-left: 50px;
}

.lil-footer-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.lil-footer-menu li {
  float: left;
}

.lil-footer-menu li a {
  display: block;
  color: #ffffff;
  text-align: center;
  padding: 16px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

.lil-footer-menu li a:hover {
  text-shadow: 0 0 7px #fff, 0 0 10px #0fa, 0 0 21px #0fa;
  /* text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 10px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa; */
}

.footer-text {
  text-transform: uppercase;
  text-align: center;
}

.footer-text-primary {
  color: #d59a2b;
}

.lil-footer-sub {
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .footer-text {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1400px) {
  .lil-footer-menu {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .lil-footer-menu-container {
    margin-bottom: 20px;
  }
  .lil-footer-menu li {
    float: none;
    margin-bottom: 10px;
  }
  .lil-footer-menu li a {
    padding: 0px;
  }
  .lil-footer-menu ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

@media only screen and (max-width: 1040px) {
  .lil-footer {
    flex-direction: column;
    height: unset;
  }
  .lil-footer-bg {
    padding: 20px 0 20px 0;
  }
  .lil-footer-menu {
    margin: 10px 0 30px 0;
  }
}
</style>
