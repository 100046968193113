<template>
  <div class="screen">
    <div class="form-col">
      <div class="columns">
        <div class="column">
          <ContactForm />
        </div>
        <div class="column is-hidden-touch">
          <div class="section-title contact-title">Contact us</div>
          <div class="contact-text">
            Questions? Comments?<br />
            Playlist requests?<br />
            Drop us an email,<br />
            we want to hear<br />
            from you!
          </div>
          <div class="social mt-5">
            <div
              class="social-bt"
              @click="gotoURL('https://www.facebook.com/LilEmpireBurger')"
            >
              <img src="~@/assets/facebook.svg" alt="" />
            </div>
            <div
              class="social-bt ml-3"
              @click="gotoURL('https://www.instagram.com/lilempireburger/')"
            >
              <img src="~@/assets/instagram.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="instruction">Press any Button To Send your Message</div>
      <div class="bounce">
        <img src="~@/assets/contact/down-arrow.svg" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
export default {
  name: "ContactScreen",
  components: {
    ContactForm,
  },
  // props: {
  //   screenState: Object,
  // },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gotoURL(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped lang="scss">
.screen {
  background: #000;
  background: url("~@/assets/contact/contactBannerBG.jpg") #000;
  background-size: cover;
}

.form-col {
  width: 80%;
}

.contact-title {
  color: #f7b3c1;
  margin-bottom: 20px;
  font-size: 3em;
}

.contact-text {
  color: #ffffff;
}

.social {
  display: flex;
}

.social-bt {
  width: 30px;
  cursor: pointer;
}

.social-bt:hover {
  filter: brightness(1.3) drop-shadow(0px 0px 10px #ffe866);
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .social-bt:hover {
      filter: none;
    }
  }
}

// .instruction {
//   color: #f7b3c1;
//   margin-bottom: 20px;
//   font-size: 3em;
//   text-align: center;
//   font-family: "Work Sans", sans-serif;
//   font-weight: 900;
//   font-size: 1.3em;
//   line-height: 1;
//   text-transform: uppercase;
// }

// .bounce {
//   position: absolute;
//   bottom: 30px;
//   left: 50%;
//   width: 60px;
//   height: 60px;
//   margin-left: -30px;
//   animation: bounce 2s infinite;
//   -webkit-animation: bounce 2s infinite;
//   -moz-animation: bounce 2s infinite;
//   -o-animation: bounce 2s infinite;
// }

// @-webkit-keyframes bounce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     -webkit-transform: translateY(0);
//   }
//   40% {
//     -webkit-transform: translateY(-30px);
//   }
//   60% {
//     -webkit-transform: translateY(-15px);
//   }
// }

// @-moz-keyframes bounce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     -moz-transform: translateY(0);
//   }
//   40% {
//     -moz-transform: translateY(-30px);
//   }
//   60% {
//     -moz-transform: translateY(-15px);
//   }
// }

// @-o-keyframes bounce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     -o-transform: translateY(0);
//   }
//   40% {
//     -o-transform: translateY(-30px);
//   }
//   60% {
//     -o-transform: translateY(-15px);
//   }
// }
// @keyframes bounce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     transform: translateY(0);
//   }
//   40% {
//     transform: translateY(-30px);
//   }
//   60% {
//     transform: translateY(-15px);
//   }
// }
</style>
