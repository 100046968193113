<template>
  <div class="screen">
    <Noise />
    <Combo :isCombo="screenState.isCombo" />
    <div :class="screenState.setQuake ? 'char-change' : ''">
      <div
        v-show="!screenState.setAction"
        class="char char-idle noselect"
        :class="screenState.setLeft ? 'char-reverse' : ''"
      >
        <img src="~@/assets/community/communityIdle.gif" alt="" />
      </div>
      <div
        v-show="screenState.setAction"
        class="char char-action noselect"
        :class="screenState.setLeft ? 'char-reverse' : ''"
      >
        <img src="~@/assets/community/communityAction.gif" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Noise from "@/components/screens/noise.vue";
import Combo from "@/components/screens/ComboEffect.vue";
export default {
  name: "JoinScreen",
  components: {
    Noise,
    Combo,
  },
  props: {
    screenState: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.screen {
  background: #000;
  background: url("~@/assets/community/communityBannerBG.jpg") #000;
  background-size: cover;
}
.char {
  overflow: hidden;
  position: relative;
}

.char-reverse {
  transform: scaleX(-1);
}
</style>
