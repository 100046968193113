<template>
  <div class="combo-wrap">
    <div class="combo" v-show="isCombo">
      <div class="combo-text-first blink-me">SUPER</div>
      <div class="combo-text-second blink-me">COMBO!</div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "ComboEffect",
  props: {
    isCombo: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showCombo() {
      console.log("showCombo");
      gsap
        .fromTo(
          ".combo-text-first",
          { opacity: 0, x: -300 },
          {
            duration: 1.4,
            x: 0,
            opacity: 1,
            ease: "elastic.out(1, 0.3)",
          }
        )
        .delay(1);
      gsap
        .fromTo(
          ".combo-text-second",
          { opacity: 0, x: 300 },
          {
            duration: 1.4,
            x: 0,
            opacity: 1,
            ease: "elastic.out(1, 0.3)",
          }
        )
        .delay(1);
      setTimeout(() => {
        this.hideCombo();
      }, 2000);
    },
    hideCombo() {
      console.log("hideCombo");
      gsap
        .to(".combo-text-first", {
          duration: 0.5,
          opacity: 0,
          ease: "ease.out",
        })
        .delay(0.1);
      gsap
        .to(".combo-text-second", {
          duration: 0.5,
          opacity: 0,
          ease: "ease.out",
        })
        .delay(0.1);
    },
  },
  watch: {
    isCombo(val) {
      if (val) {
        this.showCombo();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.combo-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  transform: translateZ(0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.combo {
  color: red;
  font-size: 8vw;
  font-weight: 900;
  font-family: "Work Sans";
  line-height: 1;
  text-align: center;
}

// .hello {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   font-size: 4.5vw;
//   animation-name: wave;
//   animation-iteration-count: infinite;
//   animation-timing-function: ease-in-out;
//   // -webkit-text-stroke-width: 3px;
//   // -webkit-text-stroke-color: black;
//   z-index: 3;
//   font-weight: bold;
//   font-family: Work Sans, sans-serif;
// }

// @for $i from 1 through 4 {
//   .hello:nth-of-type(#{$i}) {
//     animation-duration: 2s;
//     animation-delay: 0.8s - ($i / 4);
//   }
// }

// @keyframes wave {
//   40%,
//   50% {
//     transform: translate(-50%, -65%) scale(1.05);
//   }

//   0%,
//   90%,
//   100% {
//     transform: translate(-50%, -45%) scale(0.95);
//   }
// }

.blink-me {
  //animation: blinker 0.1s infinite;
  text-shadow: 3px 3px 3px rgb(255, 255, 255);
}

@keyframes blinker {
  50% {
    text-shadow: 0px 0px 10px rgba(255, 0, 0, 1);
  }
}
</style>
