<template>
  <div class="iceburger-section osect">
    <div class="container is-max-desktop">
      <div class="columns">
        <div class="column is-max-mobile is-half-tablet is-half-desktop">
          <div class="section-title iceburger-title" v-html="sectionHeading"></div>
          <div class="iceburger-text" v-html="sectionContent"></div>

          <div class="iceburger-bt">
            <div
              class="pixel-borders pixel-bt-green mt-4"
              @click="gotoPage('menu')"
            >
              view menu
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="columns">
        <div class="column">
          <div class="section-title iceburger-title">
            Don't be shy,<br />
            we've got<br />
            burgers<br />
            and fries!
          </div>
        </div>

        <div class="column is-hidden-mobile"></div>
        <div class="column">
          <div class="iceburger-text">
            Our mantra is simple.<br />
            To be the consistently<br />
            perfect burger you know<br />
            and love.
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SectionIceburgerHome",
  props: ['sectionHeading', 'sectionContent'],
  mounted() {
    let screeWidth = window.innerWidth;
    if (screeWidth > 600) {
      gsap
        .fromTo(
          ".iceburger-title, .iceburger-text, .iceburger-bt",
          { opacity: 0, y: 200 },
          {
            duration: 1.5,
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: ".iceburger-section",
              start: "top 75%",
              end: "bottom top",
            },
            ease: "expo.out",
          }
        )
        .delay(0.1);
    } //end momile if
  },
  methods: {
    gotoPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style scoped lang="scss">
// .iceburger-section {
//   background: url("~@/assets/sample.jpg"); //homeBanner.jpg
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position-x: center;
//   width: 100%;
//   // height: ;
//   padding: 200px 40px 100px 40px;
//   display: flex;
//   align-content: center;
//   justify-content: center;
// }

.iceburger-section {
  background: url("~@/assets/homeBanner.jpg"); //homeBanner.jpg
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  // height: ;
  padding: 200px 40px 100px 40px;
  display: flex;
  align-content: center;
  justify-content: center;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .iceburger-section {
      background-image: linear-gradient(
          to bottom,
          rgb(0 0 0 / 40%),
          rgb(0 0 0 / 60%)
        ),
        url("~@/assets/homeBannerMob.jpg");
      background-attachment: unset;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .iceburger-section {
    // background: url("~@/assets/homeBannerMob.jpg");
    background-image: linear-gradient(
        to bottom,
        rgb(0 0 0 / 40%),
        rgb(0 0 0 / 60%)
      ),
      url("~@/assets/homeBannerMob.jpg");
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
  }
}

.iceburger-container {
  width: 800px;
  max-width: 800px;
}

.iceburger-container {
  width: 800px;
  max-width: 800px;
}

.iceburger-title {
  color: #f7b3c1;
  margin-bottom: 20px;
}

.iceburger-text {
  color: #ffffff;
  font-size: 1.2em;
}
</style>
