<template>
  <div class="combat-section osect">
    <div class="noise no-click"></div>
    <div class="combat-wrap">
      <div class="section-title combat-title">
        ROUND {{ isActive + 1 }} - BITE!
      </div>
      <div class="section-title combat-subtitle">{{ titles[isActive] }}</div>
      <div class="combat-container">
        <div class="combat-menu">
          <div
            class="combat-icon"
            :class="isActive == 0 ? 'combat-icon-active' : ''"
            @click="setProduct(0)"
          >
            <img src="~@/assets/combat/01_empireWithCheese.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 1 ? 'combat-icon-active' : ''"
            @click="setProduct(1)"
          >
            <img src="~@/assets/combat/02_empireHamburger.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 2 ? 'combat-icon-active' : ''"
            @click="setProduct(2)"
          >
            <img src="~@/assets/combat/03_plantBasedBurger.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 3 ? 'combat-icon-active' : ''"
            @click="setProduct(3)"
          >
            <img src="~@/assets/combat/04_classicCrunchChicken.svg" alt="" />
          </div>
        </div>

        <div class="ani-container">
          <div
            v-show="isLoading"
            ref="preloader"
            class="combat-preloader"
          ></div>
          <div
            ref="combatani"
            class="combat-ani"
            :class="hideAni ? 'combat-ani-hide' : ''"
          ></div>
        </div>

        <div class="combat-menu">
          <div
            class="combat-icon"
            :class="isActive == 4 ? 'combat-icon-active' : ''"
            @click="setProduct(4)"
          >
            <img src="~@/assets/combat/05_spicyKoreanChicken.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 5 ? 'combat-icon-active' : ''"
            @click="setProduct(5)"
          >
            <img src="~@/assets/combat/06_seoulDog.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 6 ? 'combat-icon-active' : ''"
            @click="setProduct(6)"
          >
            <img src="~@/assets/combat/07_chiliDog.svg" alt="" />
          </div>
          <div
            class="combat-icon"
            :class="isActive == 7 ? 'combat-icon-active' : ''"
            @click="setProduct(7)"
          >
            <img src="~@/assets/combat/08_theJane.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="combat-button">
        <div
          class="pixel-borders pixel-bt-green mt-5"
          @click="gotoURL('https://www.lilempireburger.ca/')"
        >
          order one of these
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "CombatSection",
  data() {
    return {
      anim: null,
      // animTitle: null,
      //animSubtitle: null,
      hideAni: false,
      isLoading: false,
      isActive: 0,
      animations: [
        "01_empireWithCheese",
        "02_empireHamburger",
        "03_plantBasedBurger",
        "04_classicCrunchChicken",
        "05_spicyKoreanChicken",
        "06_seoulDog",
        "07_chiliDog",
        "08_theJane",
      ],
      titles: [
        "Empire with Cheese",
        "Empire Hamburger",
        "Veggie Burger with Cheese",
        "Classic Crunch Chicken",
        "Spicy Korean Chicken",
        "Seoul Dog",
        "Chili Dog",
        "The Jane",
      ],
    };
  },
  mounted() {
    var noiseTL = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });
    noiseTL.to(".noise", {
      duration: 0.1,
      backgroundPositionY: -4 + "px",
      //ease: "steps(1)",
    });
    gsap
      .fromTo(
        ".combat-title",
        { opacity: 0, x: -300 },
        {
          duration: 1.4,
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ".combat-section",
            start: "top 75%",
            end: "bottom top",
          },
          ease: "elastic.out(1, 0.3)",
        }
      )
      .delay(0.5);
    gsap
      .fromTo(
        ".combat-subtitle",
        { opacity: 0, x: 300 },
        {
          duration: 1.4,
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ".combat-section",
            start: "top 75%",
            end: "bottom top",
          },
          ease: "elastic.out(1, 0.3)",
        }
      )
      .delay(1);

    this.anim = lottie.loadAnimation({
      container: this.$refs.combatani,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: this.$env.siteURL + "anim/01_empireWithCheese.json",
    });

    let preloader = lottie.loadAnimation({
      container: this.$refs.preloader,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.$env.siteURL + "anim/combat_loader.json",
    });
    preloader.setDirection(-1);
  },
  methods: {
    gotoURL(url) {
      window.open(url, "_blank").focus();
    },
    // eslint-disable-next-line vue/no-dupe-keys
    animTitle() {
      gsap
        .fromTo(
          ".combat-title",
          { opacity: 0, x: -300 },
          {
            duration: 1.4,
            x: 0,
            opacity: 1,
            ease: "elastic.out(1, 0.3)",
          }
        )
        .delay(0.5);
    },
    animSubtitle() {
      gsap
        .fromTo(
          ".combat-subtitle",
          { opacity: 0, x: 300 },
          {
            duration: 1.4,
            x: 0,
            opacity: 1,
            ease: "elastic.out(1, 0.3)",
          }
        )
        .delay(0.5);
    },
    setProduct(id) {
      this.isActive = id;
      //onsole.log(id);
      //this.anim.destroy();
      this.animTitle();
      this.animSubtitle();
      this.hideAni = true;
      this.isLoading = true;
      setTimeout(() => {
        this.loadAni(id);
        this.hideAni = false;
      }, 2000);
      // this.anim = lottie.loadAnimation({
      //   container: this.$refs.combatani,
      //   renderer: "svg",
      //   loop: false,
      //   autoplay: true,
      //   path:
      //     "https://assets1.lottiefiles.com/packages/" +
      //     this.animations[id] +
      //     ".json",
      // });
    },
    loadAni(id) {
      this.anim.destroy();
      this.anim = lottie.loadAnimation({
        container: this.$refs.combatani,
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: this.$env.siteURL + "anim/" + this.animations[id] + ".json",
      });
      this.isLoading = false;
      //this.anim.play();
      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 1000);
    },
  },
};
</script>

<style scoped>
.noise {
  position: absolute;
  background: url("~@/assets/noise.png");
  opacity: 0.1;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.combat-section {
  background: url("~@/assets/mkBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  position: relative;
  /* height: 500px; */
  /* // width: 100%;
  // // height: ;
  // display: flex;
  // align-content: center;
  // justify-content: center;
  position: relative; */
  /* display: flex;
  align-content: center;
  justify-content: center; */
}
.combat-wrap {
  margin: 100px 0 100px 0;
}
.combat-title {
  color: #df1531;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px #fff;
}
.combat-subtitle {
  color: #d4972a;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px #fff;
}
.combat-button {
  text-align: center;
}
.combat-container {
  display: flex;
  align-content: center;
  justify-content: center;
}
.combat-menu {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.combat-icon {
  width: 125px;
  margin: 5px;
  cursor: pointer;
  padding: 5px;
  border: #e6c38200 4px solid;
  transition: all 0.3s;
}
.combat-icon:hover {
  border: #e6c382 4px solid;
  border-radius: 10px;
}

.combat-icon-active {
  border: #e6c382 4px solid;
  border-radius: 10px;
}

.combat-ani {
  margin-left: 2em;
  margin-right: 2em;
  width: 350px;
  transition: opacity 0.4s;
}

.combat-ani-hide {
  opacity: 0;
}

.ani-container {
  /* display: flex;
  align-content: center;
  justify-content: center; */
  position: relative;
}

.combat-preloader {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 350px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 750px) {
  .combat-container {
    flex-direction: column;
  }
  .combat-menu {
    flex-direction: row;
  }
  .ani-container {
    margin: auto;
  }
  .combat-ani {
    width: 80%;
    margin: auto;
    height: 400px;
  }
}

/* @media only screen and (min-width: 600px) {...}
@media only screen and (min-width: 768px) {...}
@media only screen and (min-width: 992px) {...}
@media only screen and (min-width: 1200px) {...} */

@media only screen and (max-width: 1025px) {
  .combat-icon {
    width: 95px;
  }
  .combat-ani {
    width: 300px;
  }
}
</style>
