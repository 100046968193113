<template>
  <div class="screen">
    <Noise />
    <Combo :isCombo="screenState.isCombo" />
    <div
      v-show="!screenState.setAction"
      class="char char-idle noselect"
      :class="[
        screenState.setLeft ? 'char-reverse' : '',
        screenState.setQuake ? 'glow-change' : '',
      ]"
    >
      <img src="~@/assets/menu/menuIdle.gif" alt="" />
    </div>
    <div
      v-show="screenState.setAction"
      class="char char-action noselect"
      :class="screenState.setLeft ? 'char-reverse' : ''"
    >
      <img src="~@/assets/menu/menuAction.gif" alt="" />
    </div>
    <div
      class="screen-bg"
      :class="screenState.setQuake ? 'blur-change' : ''"
    ></div>
  </div>
</template>

<script>
import Noise from "@/components/screens/noise.vue";
import Combo from "@/components/screens/ComboEffect.vue";
export default {
  name: "MenuScreen",
  components: {
    Noise,
    Combo,
  },
  props: {
    screenState: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
// .screen {
//   background: #000;
//   background: url("~@/assets/menu/menuBannerBG2.jpg") #000;
//   background-size: cover;
// }
.screen-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.55) 0%,
      rgb(255 255 255 / 0%) 10%,
      rgb(255 255 255 / 0%) 90%,
      rgba(255, 82, 178, 0.49) 100%
    ),
    url("~@/assets/menu/menuBannerBG.jpg"); //homeBannerBG_01
  background-size: cover;
  background-position-y: center;
  transform: translateZ(0);
}
.char {
  overflow: hidden;
  position: relative;
}
.char-reverse {
  transform: scaleX(-1);
}
</style>
