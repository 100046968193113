<template>
  <div class="screen">
    <Noise />
    <Combo :isCombo="screenState.isCombo" />
    <div :class="screenState.setLeft ? 'char-reverse' : ''">
      <div
        v-show="!screenState.setAction"
        class="char char-idle noselect animated"
        :class="screenState.setQuake ? 'color-change' : ''"
      >
        <img src="~@/assets/bbIdle.gif" alt="" />
      </div>
    </div>

    <div :class="screenState.setLeft ? 'char-reverse' : ''">
      <div v-show="screenState.setAction" class="char char-action noselect">
        <img src="~@/assets/bbAction.gif" alt="" />
      </div>
    </div>
    <div
      class="screen-bg"
      :class="screenState.setQuake ? 'intensifies' : ''"
    ></div>
  </div>
</template>

<script>
import Noise from "@/components/screens/noise.vue";
import Combo from "@/components/screens/ComboEffect.vue";
export default {
  name: "HomeScreen",
  components: {
    Noise,
    Combo,
  },
  props: {
    screenState: Object,
  },
  data() {
    return {};
  },
  methods: {},
};

// filter: hue-rotate(180deg) contrast(200%)
//   drop-shadow(16px 16px 20px rgb(255, 0, 0));
// linear-gradient(
//           to bottom,
//           rgb(0 0 0 / 40%),
//           rgb(0 0 0 / 60%)
//         ),
</script>

<style scoped lang="scss">
.screen-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.55) 0%,
      rgb(255 255 255 / 0%) 10%,
      rgb(255 255 255 / 0%) 90%,
      rgba(255, 82, 178, 0.49) 100%
    ),
    url("~@/assets/homeBannerBG_01.jpg"); //homeBannerBG_01
  background-size: cover;
  background-position-y: center;
  transform: translateZ(0);
}

.screen-bg1 {
  background: #000;
  background-image: url("~@/assets/homeBannerBG_01.jpg"); //homeBannerBG_01
  background-size: cover;
  background-position-y: center;
}

.screen-bg2 {
  background: #000;
  background-image: url("~@/assets/homeBannerBG_02.jpg");
  background-size: cover;
  background-position-y: center;
}

.char {
  // width: 600px;
  // height: 500px;
  overflow: hidden;
  position: relative;
  transform: scale(0.8);
}

@media only screen and (max-width: 1000px) {
  .char {
    bottom: -50px;
  }
}

@media only screen and (min-width: 600px) {
  .char {
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 768px) {
  .char {
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 992px) {
  .char {
    transform: scale(0.7);
  }
}

@media only screen and (min-width: 1200px) {
  .char {
    transform: scale(0.55);
  }
}

.char-reverse {
  z-index: 3;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: scaleX(-1);
}
</style>
