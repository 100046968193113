<template>
  <div class="section merch-section osect">
    <div class="container is-max-desktop merch-container merch-layer">
      <div class="columns">
        <div class="column">
          <img src="~@/assets/swag.png" alt="" />
        </div>
        <div class="column">
          <div class="section-title merch-title" v-html="sectionHeading"></div>
          <div class="merch-text" v-html="sectionContent"></div>
          <div class="merch-bt">
            <div class="pixel-borders pixel-bt-blue mt-5">Shop Merch</div>
          </div>
        </div>
      </div>
    </div>

    <div class="rollers-container">
      <div class="rollers">
        <img src="~@/assets/rollerSkate.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SectionMerchHome",
  props: ['sectionHeading', 'sectionContent'],
  mounted() {
    let screeWidth = window.innerWidth;
    let maxOpacity = 1;
    if (screeWidth > 600) {
      if (screeWidth < 1000) {
        maxOpacity = 0.15;
      }
      gsap
        .fromTo(
          ".merch-title, .merch-text, .merch-bt",
          { opacity: 0, y: 200 },
          {
            duration: 1.5,
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: ".merch-section",
              start: "top 75%",
              end: "bottom top",
            },
            ease: "expo.out",
          }
        )
        .delay(0.1);
      gsap
        .fromTo(
          ".rollers-container",
          { opacity: 0, x: 200 },
          {
            duration: maxOpacity,
            x: 0,
            opacity: maxOpacity,
            scrollTrigger: {
              trigger: ".merch-section",
              // start:"top 75%",
              // end:"bottom 5%",
              start: "top 75%",
              end: "bottom top",
              ease: "sine.out",
              //markers: true,
              //toggleActions: "restart reset play",
            },
          }
        )
        .delay(0.1);
    } //end momile if
  },
};
</script>

<style scoped lang="scss">
.merch-section {
  background: url("~@/assets/homeAndAboutPinkBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  // width: 100%;
  // // height: ;
  // display: flex;
  // align-content: center;
  // justify-content: center;
  position: relative;
}

// .merch-container {
//   // padding: 200px 40px 100px 40px;
// }

.merch-title {
  color: #1b5531;
  margin-bottom: 20px;
}

.merch-text {
  // color: #ffffff;
  font-size: 1.2em;
}

.rollers-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 400px;
  opacity: 0;
}

.rollers {
  width: 400px;
  margin-bottom: -6px;
}

.merch-layer {
  z-index: 5;
}

.lil-sticker {
  position: absolute;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rollers-container {
    opacity: 0.15;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .rollers-container {
    opacity: 0.15;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .rollers-container {
    opacity: 0.15;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .rollers-container {
    opacity: 0.15;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  // .arcade-container {
  //   opacity: 1;
  // }
  // .rollers-container {
  //   opacity: 0.3;
  // }
}

@media only screen and (min-width: 1440px) {
  // .arcade-container {
  //   opacity: 1;
  // }
  // .rollers-container {
  //   opacity: 0.3;
  // }
}

@media only screen and (min-width: 1825px) {

}
</style>
