import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import {
  ConfigProgrammatic,
  Input,
  Button,
  Checkbox,
  Field,
  Modal,
  Loading,
  Message,
  Toast,
} from "buefy";
//import Buefy from "buefy";
import VueMeta from "vue-meta";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

Vue.prototype.$env = {
  siteTitle: "Lil Empire Burger",
  siteDescription: "Delivery now available from both locations through Skip the Dishes! Follow us on Instagram.",
  siteURL: "https://lilempireburger.com/",
  shareImg: "/img/share.jpg",
  api: "https://lilempireburger.com/api",
};

//Vue.prototype.$apiUrl = 'http://lilempire-admin.local/'
Vue.prototype.$apiUrl = 'https://lilempireburger.com/cms/'

// Vue.prototype.$env = {
//   siteTitle: "Lil Empire Burger",
//   siteDescription: "Delivery now available from both locations through Skip the Dishes! Follow us on Instagram.",
//   siteURL: "http://lilempire.v4tnx2m0ek-dv13xjrzq6gq.p.runcloud.link/",
//   shareImg: "/img/share.jpg",
//   api: "http://lilempire.v4tnx2m0ek-dv13xjrzq6gq.p.runcloud.link/api",
// };

// Vue.use(Buefy, {
//   defaultIconPack: "mdi",
// });
Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Field);
Vue.use(Modal);
Vue.use(Loading);
Vue.use(Message);
Vue.use(Toast);
ConfigProgrammatic.setOptions({
  defaultIconPack: "mdi",
  // ...
});
Vue.use(VueAxios, axios);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
