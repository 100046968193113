<template>
  <div class="menu-section">
    <div class="menu-co">
      <div class="header-pt">
        <div class="menu-container noselect">
          <div class="menu">
            <router-link
              to="/menu"
              :class="$route.name == 'menu' ? 'm-active' : ''"
              >menu</router-link
            >
            <router-link
              to="/about"
              :class="$route.name == 'about' ? 'm-active' : ''"
              >about</router-link
            >
            <a href="https://www.lilempireburger.ca/#5" target="_blank">swag</a>
            <router-link
              to="/contact"
              :class="$route.name == 'contact' ? 'm-active' : ''"
              >contact</router-link
            >
          </div>
          <div @click="gotoPage('')" class="logo clickable">
            <img src="~@/assets/lilLogo.svg" alt="" />
          </div>
          <div class="menu">
            <router-link
              to="/join"
              :class="$route.name == 'join' ? 'm-active' : ''"
              >join us</router-link
            >
          </div>

          <div
            class="pixel-borders pixel-bt ml-5"
            @click="gotoURL('https://www.lilempireburger.ca/')"
          >
            place an order
          </div>
          <div class="social ml-5">
            <div
              class="social-bt"
              @click="gotoURL('https://www.facebook.com/LilEmpireBurger')"
            >
              <img src="~@/assets/facebook.svg" alt="" />
            </div>
            <div
              class="social-bt"
              @click="gotoURL('https://www.instagram.com/lilempireburger/')"
            >
              <img src="~@/assets/instagram.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="mobile-menu-container">
          <div class="logo"><img src="~@/assets/lilLogo.svg" alt="" /></div>
          <div class="bars" @click="showMenu()">
            <img src="~@/assets/bars.svg" alt="" />
          </div>
        </div>
        <!-- themod -->
        <div
          class="header-screen-wrap"
          v-if="['menu'].indexOf($route.name) !== -1"
        >
          <MenuScreen :screenState="screenState" />
        </div>
        <div
          class="header-screen-wrap"
          v-if="['about'].indexOf($route.name) !== -1"
        >
          <AboutScreen :screenState="screenState" />
        </div>
        <div
          class="header-screen-wrap"
          v-if="['contact'].indexOf($route.name) !== -1"
        >
          <ContactScreen :screenState="screenState" />
        </div>
        <div
          class="header-screen-wrap"
          v-if="['join'].indexOf($route.name) !== -1"
        >
          <JoinScreen :screenState="screenState" />
        </div>
        <div
          class="header-screen-wrap"
          v-if="['community'].indexOf($route.name) !== -1"
        >
          <CommunityScreen :screenState="screenState" />
        </div>
        <div
          class="header-screen-wrap"
          v-if="['home'].indexOf($route.name) !== -1"
        >
          <HomeScreen :screenState="screenState" />
        </div>
        <!-- endmod -->
      </div>
      <div class="header-screen-bg"></div>
      <div class="header-screen-bg-image"></div>
      <div class="deck" :class="$route.name == 'contact' ? 'no-click' : ''">
        <!-- <img
          style="width: 1px; height: 1px"
          src="~@/assets/leftPadBlue-1-2.png"
        />
        <img
          style="width: 1px; height: 1px"
          src="~@/assets/leftButtonOrange-2.png"
        />
        <img
          style="width: 1px; height: 1px"
          src="~@/assets/rightButtonBlue-2.png"
        />
        <img
          style="width: 1px; height: 1px"
          src="~@/assets/rightPadOrange-1-2.png" v-show="!leftPad" v-show="leftPad"
        /> -->
        <div class="deck-ui-wrap noselect">
          <div class="pad" @click="doLeftPad">
            <div class="left-pad pad-image">
              <img
                class="noselect no-click"
                src="~@/assets/PadHolder.png"
                alt=""
              />
            </div>
          </div>
          <div class="deck-bt" @click="doQuake">
            <div class="left-button button-image">
              <img
                class="noselect no-click"
                src="~@/assets/ButtonHolder.png"
                alt=""
              />
            </div>
          </div>
          <div class="deck-bt" @click="doAction">
            <div class="right-button button-image">
              <img
                class="noselect no-click"
                src="~@/assets/ButtonHolder.png"
                alt=""
              />
            </div>
          </div>
          <div class="pad" @click="doRightPad">
            <div class="right-pad pad-image">
              <img
                class="noselect no-click"
                src="~@/assets/PadHolder.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { gsap } from "gsap";
import HomeScreen from "@/components/screens/HomeScreen.vue";
import MenuScreen from "@/components/screens/MenuScreen.vue";
import AboutScreen from "@/components/screens/AboutScreen.vue";
import ContactScreen from "@/components/screens/ContactScreen.vue";
import JoinScreen from "@/components/screens/JoinScreen.vue";
import CommunityScreen from "@/components/screens/CommunityScreen.vue";

const pongMoveUP = new CustomEvent("joistik", { detail: "up" });
const pongMoveDOWN = new CustomEvent("joistik", { detail: "down" });
const noAction = new CustomEvent("joistik", { detail: "no" });

export default {
  name: "Navbar",
  components: {
    HomeScreen,
    MenuScreen,
    AboutScreen,
    ContactScreen,
    JoinScreen,
    CommunityScreen,
  },
  data() {
    return {
      screenState: {
        setLeft: false,
        setQuake: false,
        setAction: false,
        isCombo: false,
      },
      leftPad: false,
      rightPad: false,
      leftBt: false,
      leftBtCombo: false,
      rightBt: false,
      rightBtCombo: false,
    };
  },
  mounted() {},
  methods: {
    setCombo() {
      if (this.leftBtCombo && this.rightBtCombo) {
        this.screenState.isCombo = true;
        setTimeout(() => {
          this.screenState.isCombo = false;
        }, 3000);
      } else {
        this.screenState.isCombo = false;
      }
    },
    vibrate(level) {
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (!isSafari) {
        navigator.vibrate(level);
      }
    },
    pongUP() {
      window.dispatchEvent(pongMoveUP);
      setTimeout(() => {
        window.dispatchEvent(noAction);
      }, 100);
    },
    pongDOWN() {
      window.dispatchEvent(pongMoveDOWN);
      setTimeout(() => {
        window.dispatchEvent(noAction);
      }, 100);
    },
    gotoURL(url) {
      window.open(url, "_blank").focus();
    },
    gotoPage(page) {
      if (this.$route.path !== "/" + page) {
        this.$router.push({ path: "/" + page });
      }
    },
    showMenu() {
      this.$store.commit("show", "menu");
    },
    doLeftPad() {
      this.pongUP();
      this.screenState.setLeft = true;
      this.leftPad = true;
      this.vibrate(200);
      setTimeout(() => {
        this.leftPad = false;
      }, 100);
    },
    doRightPad() {
      this.pongDOWN();
      this.screenState.setLeft = false;
      this.rightPad = true;
      this.vibrate(200);
      setTimeout(() => {
        this.rightPad = false;
      }, 100);
    },
    doAction() {
      this.pongUP();
      this.screenState.setAction = true;
      this.vibrate([200, 200, 200]);
      setTimeout(() => {
        this.screenState.setAction = false;
      }, 1700);
      this.rightBt = true;
      setTimeout(() => {
        this.rightBt = false;
      }, 100);
      this.rightBtCombo = true;
      this.setCombo();
      setTimeout(() => {
        this.rightBtCombo = false;
      }, 500);
    },
    doQuake() {
      this.pongDOWN();
      this.screenState.setQuake = true;
      this.vibrate([200, 200, 200]);
      setTimeout(() => {
        this.screenState.setQuake = false;
      }, 3000);
      this.leftBt = true;
      setTimeout(() => {
        this.leftBt = false;
      }, 100);
      this.leftBtCombo = true;
      this.setCombo();
      setTimeout(() => {
        this.leftBtCombo = false;
      }, 500);
    },
    // setBg() {
    //   this.pongDOWN();
    //   this.screenState.setBG = !this.screenState.setBG;
    //   this.leftBt = true;
    //   setTimeout(() => {
    //     this.leftBt = false;
    //   }, 100);
    // },
  },
};
</script>

<style scoped lang="scss">
.menu-section {
  display: block;
  height: 1265px;
}
.menu-co {
  position: relative;
  height: inherit;
  background-color: #f7a8d5;
}
.header-pt {
  background: url("~@/assets/header.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 586px;
  position: absolute;
  z-index: 2;
  //background: #0c2835;
  //pointer-events: none;
}
.header-screen-bg {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 50%,
    rgb(1, 66, 128) 100%
  );
  //url("~@/assets/topBannerBG.jpg")
  background-size: cover;
  background-blend-mode: multiply;
  position: absolute;
  width: 100%;
  height: 95vh;
  z-index: 1;
  opacity: 0.6;
  //box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.055);
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.header-screen-bg-image {
  background: url("~@/assets/topBannerBG.jpg");
  background-size: cover;
  background-blend-mode: multiply;
  position: absolute;
  width: 100%;
  height: 95vh;
  z-index: 0;
  //box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.055);
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.header-screen-wrap {
  //  position: absolute;
  width: 100%;
  // height: 900px;
  // z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(0);
}

// @media not all and (min-resolution: 0.001dpcm) {
//   @supports (-webkit-appearance: none) {
//     .header-screen-wrap {
//       transform: translateZ(-0px);
//     }
//   }
// }

.menu-container {
  height: 140px;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu-container {
  height: 140px;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bars {
  color: #ffffff;
  width: 40px;
  cursor: pointer;
}

.menu {
  font-family: "Work Sans", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 1.5em;
  font-style: italic;
  text-shadow: 4px 4px #0c2835;
  text-transform: uppercase;
}

.menu a {
  color: #ffffff;
  position: relative;
}

.menu a:not(:first-child) {
  margin-left: 25px;
}

.menu a:hover {
  -webkit-text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
}

.m-active:before {
  content: url("~@/assets/crown.svg");
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%);
  width: 30px;
  height: 30px;
  // z-index: 3;
}

.m-active:after {
  content: " ";
  display: block;
  background: #d59a2b;
  height: 2px;
  width: 100%;
  left: 0px;
  position: absolute;
  bottom: -6px;
}

.logo {
  width: 200px;
  margin-right: 50px;
  margin-left: 50px;
}
.logo:hover {
  filter: brightness(1.3) drop-shadow(0px 0px 10px #ffe866);
}
.social {
  display: flex;
}

.social-bt {
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.social-bt:hover {
  filter: brightness(1.3) drop-shadow(0px 0px 10px #ffe866);
}

.deck {
  // background: url("~@/assets/deck.jpg");
  // background-repeat: no-repeat;
  //background-size: 100% 100%;
  // background-size: cover;
  // background-position-x: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 2%,
    rgba(243, 186, 239, 1) 3%,
    rgba(241, 172, 235, 1) 10%,
    rgba(240, 153, 221, 1) 17%,
    rgba(240, 165, 224, 1) 62%,
    rgba(243, 179, 229, 1) 90%,
    rgba(242, 164, 212, 1) 100%
  );
  width: 100%;
  height: 366px;
  top: 900px;
  // position: absolute;
  // z-index: 2;
  //background: gray;
  // display: flex;
  // justify-content: space-between;
  position: relative;
}
.deck-ui-wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // margin: auto;
  bottom: 0px;
  //background: #0fa;
  z-index: 4;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pad {
  position: relative;
  cursor: pointer;
  bottom: 0;
  //background: pink;
}
.pad-layer2 {
  position: absolute;
  top: 0;
}

.deck-bt {
  cursor: pointer;
  bottom: 0;
}

.pad-image {
  width: 100%;
  height: 100%;
  //background: #0fa;
}
.left-pad {
  background-image: url("~@/assets/leftPad1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.left-pad:active {
  background-image: url("~@/assets/leftPad2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.right-pad {
  background-image: url("~@/assets/rightPad1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.right-pad:active {
  background-image: url("~@/assets/rightPad2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.button-image {
  width: 100%;
  height: 100%;
  //background: #0fa;
}

.left-button {
  background-image: url("~@/assets/leftButton1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.left-button:active {
  background-image: url("~@/assets/leftButton2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.right-button {
  background-image: url("~@/assets/rightButton1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.right-button:active {
  background-image: url("~@/assets/rightButton2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  .menu-container {
    display: none;
  }
  .mobile-menu-container {
    display: flex;
    height: 90px;
  }
  .deck {
    height: 100px;
    top: 60vh;
  }
  .menu-section {
    height: calc(60vh + 150px);
  }
  .header-pt {
    height: 350px;
  }
  .deck-ui-wrap {
    width: 100%;
    bottom: 20%;
  }
  .header-screen-bg-image,
  .header-screen-bg {
    height: 60vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mobile-menu-container {
    display: flex;
    height: 90px;
  }
  .menu-container {
    display: none;
  }
  .deck {
    height: 100px;
    top: 60vh;
  }
  .menu-section {
    height: calc(60vh + 150px);
  }
  .header-pt {
    height: 350px;
  }
  .deck-ui-wrap {
    width: 100%;
  }
  .header-screen-bg-image,
  .header-screen-bg {
    height: 60vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .menu-container {
    display: none;
  }
  .mobile-menu-container {
    display: flex;
    height: 140px;
  }
  .deck {
    height: 200px;
    top: 82vh;
  }
  .menu-section {
    height: 104vh;
  }
  .header-pt {
    height: 586px;
  }
  .deck-ui-wrap {
    width: 100%;
  }
  .header-screen-bg-image,
  .header-screen-bg {
    height: 82vh;
  }
}

@media only screen and (min-width: 992px) {
  .menu-container {
    display: none;
  }
  .menu-section {
    height: calc(100vh + 100px);
  }
  .deck {
    top: 82vh;
    height: 140px;
  }
  .deck-ui-wrap {
    width: 100%;
  }
  .header-screen-bg-image,
  .header-screen-bg {
    height: 90vh;
  }
}

@media only screen and (min-width: 1200px) {
  .menu-container {
    display: flex;
  }
  .menu-section {
    height: calc(100vh + 100px);
  }
  .mobile-menu-container {
    display: none;
  }
  .deck {
    top: 85vh;
    height: 140px;
  }
  .deck-ui-wrap {
    width: 75vw;
    bottom: -20px;
  }
}

@media only screen and (min-width: 1900px) {
  .menu-container {
    display: flex;
  }
  .menu-section {
    height: calc(100vh + 100px);
  }
  .mobile-menu-container {
    display: none;
  }
  .deck {
    top: 85vh;
    height: 140px;
  }
  .deck-ui-wrap {
    width: 80vw;
    bottom: -3rem;
  }
}

@media only screen and (max-height: 600px) {
  .menu-section {
    height: 172vh;
  }
  .deck {
    top: 125vh;
    height: 140px;
    //height: 230px;
  }
  .header-screen-bg-image,
  .header-screen-bg {
    height: 125vh;
  }
}

// @media only screen and (max-height: 700px) {
//   .menu-section {
//     height: 172vh;
//   }
//   .deck {
//     top: 93vh;
//     height: 160px;
//     //height: 230px;
//   }
//   .header-screen-bg-image,
//   .header-screen-bg {
//     height: 93vh;
//   }
// }

// @media only screen and (min-height: 1080px) {
//   .deck {
//     top: 80vh;
//     height: 223px;
//   }
// }

// @media only screen and (min-height: 1090px) {
//   .menu-section {
//     height: 104vh;
//   }
//   .header-screen-bg,
//   .header-screen-bg-image {
//     height: 93vh;
//   }
//   .deck {
//     top: 80vh;
//     height: 230px;
//   }
// }

// @media only screen and (min-height: 1300px) {
//   .menu-section {
//     height: 104vh;
//   }
// }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .menu a:hover {
      text-shadow: 0 0 7px #fff, 0 0 10px #0fa;
    }
    .logo:hover {
      filter: none;
    }
    .social-bt:hover {
      filter: none;
    }
  }
}
</style>
