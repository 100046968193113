<template>
  <div class="grid-section osect">
    <div class="section-ct">
      <div class="section-title grid-title" v-html="sectionHeading"></div>
      <div class="grid-text" v-html="sectionContent"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SectionGridHome",
  props: ['sectionHeading', 'sectionContent'],
  mounted() {
    let screeWidth = window.innerWidth;
    if (screeWidth > 600) {
      gsap
        .fromTo(
          ".section-ct",
          { opacity: 0, scale: 0.5 },
          {
            duration: 1.5,
            opacity: 1,
            scale: 1,
            scrollTrigger: {
              trigger: ".grid-section",
              start: "top 75%",
              end: "bottom top",
            },
            //ease: "expo.out",
          }
        )
        .delay(0.1);
    } //end momile if
  },
};
</script>

<style scoped lang="scss">
.grid-section {
  background: url("~@/assets/deepBlueBGclean.jpg"); //homeBanner.jpg
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  // height: ;
  padding: 80px 40px 100px 40px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.grid-title {
  color: #93d2e7;
  margin-bottom: 20px;
}

.grid-text {
  color: #ffffff;
}
</style>
