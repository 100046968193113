<template>
  <div class="insta-feed">
    <!-- <template v-for="(image, index) in images">
      <div
        v-if="index < wIW - 1"
        :key="'inst' + index"
        class="insta-image"
        :style="{
          backgroundImage: 'url(' + $env.api + '/insta/' + image + ')',
        }"
      ></div>
    </template>     -->
    <!-- LightWidget WIDGET -->

    <iframe
      src="https://cdn.lightwidget.com/widgets/37f7cca1bd195099b7ab5263732b9ab1.html"
      scrolling="no"
      allowtransparency="true"
      class="lightwidget-widget"
      style="width: 100%; border: 0; overflow: hidden"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "InstagramFeed",
  data() {
    return {
      appID: "382840237080698",
      key: "a1274c1a52aa24cfcb9ca38fe8a668c0",
      instaData: {
        access_token: "a1274c1a52aa24cfcb9ca38fe8a668c0",
        count: 10,
      },
      images: [
        "inst1.png",
        "inst2.png",
        "inst3.png",
        "inst4.png",
        "inst5.png",
        "inst6.png",
        "inst7.png",
        "inst8.png",
      ],
      wIW: null,
    };
  },
  mounted() {
    //this.getFeed(); lilempireburger
    //this.getFeed();
    //this.wIW = Math.ceil(Math.ceil(window.innerWidth / 100) / 2);
    //window.addEventListener("resize", this.reportWindowSize);
  },
  methods: {
    //a function to get instagram access token

    reportWindowSize() {
      this.wIW = Math.ceil(Math.ceil(window.innerWidth / 100) / 2);
      //console.log(this.wIW);
    },
    getFeed() {
      this.axios
        .get(
          "https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=a1274c1a52aa24cfcb9ca38fe8a668c0"
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.insta-feed {
  display: flex;
  flex-direction: row;
  background: #fff;
}
.insta-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
</style>
